/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import Button from "~components/Button";
import Carousel from "~components/Carousel";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import ProductGrid from "~components/ProductGrid";
import SEO from "~components/SEO";
import Arrow from "~components/svg/Arrow";
import { pushProductEvent } from "~utils/analytics";
import {
  getProductByHandle,
  getSelectableOptions,
  parseProducts
} from "~utils/shopify";

const ProductPage = ({ data, location }) => {
  const { addToCart, inventoryCache, inventoryFetched } = useContext(
    AppContext
  );
  const { device, scrollTop } = useContext(DocumentContext);

  const addToCartRef = useRef();
  const footerRef = useRef();

  const [addableProduct, setAddableProduct] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);
  const [expandedAttribute, setExpandedAttribute] = useState(null);
  const [options, setOptions] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [stickyAddVisible, setStickyAddVisible] = useState(false);

  //

  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  const products = parseProducts(data);
  const product = getProductByHandle(frontmatter.shopifyHandle, products);

  //

  const setPurchaseVariant = variant => {
    if (!product) {
      return;
    }

    const productClone = JSON.parse(JSON.stringify(product));

    productClone.variant = variant;

    setAddableProduct(productClone);
  };

  const selectVariantForOption = (key, value) => {
    let matchedVariant;

    product.variants.forEach(variant => {
      if (matchedVariant) {
        return;
      }

      variant.selectedOptions.forEach(selectedOption => {
        if (matchedVariant || selectedOption.name !== key) {
          return;
        }

        if (selectedOption.value === value && !matchedVariant) {
          matchedVariant = variant;
        }
      });
    });

    if (matchedVariant) {
      setPurchaseVariant(matchedVariant);
    }
  };

  //----------------------------------------------------------------------------
  //
  // onload hook

  // useEffect(() => {
  //   console.log(inventoryCache);
  // }, [inventoryCache]);

  useEffect(() => {
    const parsedProduct = getProductByHandle(
      frontmatter.shopifyHandle,
      products
    );

    const defaultVariant = parsedProduct.variants[0];

    parsedProduct.variant = defaultVariant;

    pushProductEvent(parsedProduct, `productView`);
    setOptions(getSelectableOptions(parsedProduct));
    setAddableProduct(parsedProduct);
  }, []);

  //----------------------------------------------------------------------------
  //
  // scroll hook

  useEffect(() => {
    if (!addToCartRef?.current) {
      return;
    }

    const boundingRect = addToCartRef.current.getBoundingClientRect();

    if (boundingRect.top < 75) {
      if (!stickyAddVisible) {
        setStickyAddVisible(true);
      }
    } else if (stickyAddVisible) {
      setStickyAddVisible(false);
    }
  }, [scrollTop]);

  //----------------------------------------------------------------------------
  //
  // product initialisation hook

  useEffect(() => {
    if (!product || product.checkoutId) {
      return;
    }

    setPurchaseVariant(product.variant);
  }, [product]);

  useEffect(() => {
    if (addingToCart) {
      setTimeout(() => {
        setAddingToCart(false);
      }, 2000);
    }
  }, [addingToCart]);

  //

  const stocked =
    inventoryCache?.override ||
    inventoryCache?.[addableProduct?.variant?.id] > 0;

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <div
        className={`product-page__sticky-add desktop-only ${
          stickyAddVisible ? `active` : `pointer-events-none`
        } w-full fixed right-0 top-0 left-0 z-20 pt-3 pb-3 bg-white border-b-black`}
        style={{ top: `75px` }}
      >
        <div className="grid items-center">
          <div className="grid-end-6 sm:grid-end-12 xs:flex xs:items-center xs:justify-between xs:mb-2">
            <h2 className="b1">{product.frontmatter.title}</h2>
            {addableProduct?.variant?.title &&
              addableProduct?.variant?.title !== `Default Title` && (
                <h2 className="b2">{addableProduct.variant.title}</h2>
              )}
          </div>

          <div className="grid-end-5 sm:grid-end-12 grid-start-8 sm:grid-start-1 relative flex">
            <div className="product-page__quantity relative flex items-center justify-center border mr-3 xs:mr-0">
              <button
                type="button"
                className={`product-page__quantity__button relative block ${
                  quantity <= 1 ? `pointer-events-none opacity-50` : ``
                }`}
                onClick={() => {
                  if (quantity < 2) {
                    return;
                  }

                  setQuantity(quantity - 1);
                }}
                transparent
              >
                <span className="b1">—</span>
              </button>

              <div className="relative block pointer-events-none text-center">
                <span className="b1">{quantity}</span>
              </div>

              <button
                type="button"
                className="product-page__quantity__button relative block"
                onClick={() => setQuantity(quantity + 1)}
                transparent
              >
                <span className="b1">+</span>
              </button>
            </div>

            <Button
              color={addingToCart ? `grey` : `black`}
              className={`${stocked ? `` : `opacity-50 pointer-events-none`} ${
                addingToCart ? `pointer-events-none` : ``
              } transition-background w-3/4 relative flex items-center justify-center`}
              onClick={() => {
                setAddingToCart(true);

                if (stocked) {
                  addToCart(addableProduct, quantity);
                }
              }}
            >
              {addableProduct?.variant?.price && (
                <span className="b1">
                  {addingToCart ? (
                    `Added!`
                  ) : (
                    <>
                      {stocked
                        ? `Add To Cart - $${addableProduct.variant.price}`
                        : `Sold Out`}
                    </>
                  )}
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>

      <Layout className="product-page w-full relative overflow-x-hidden">
        <section className="grid">
          {product && (
            <article className="animation-appear-right-slow animation-delay-2 grid-end-6 sm:grid-end-12 sm:grid-start-1">
              {device && device === `desktop` && (
                <div className="offgrid-left offgrid-left--no-touch">
                  {product?.frontmatter?.gallery?.[0] &&
                    product.frontmatter.gallery.map(
                      (galleryItem, galleryItemIndex) => {
                        const key = `gallery-item-${galleryItemIndex}`;

                        let visible = loadedImages.includes(galleryItemIndex);

                        if (galleryItemIndex === 0 || scrollTop > 96) {
                          visible = true;

                          if (!loadedImages.includes(galleryItemIndex)) {
                            setLoadedImages([
                              ...loadedImages,
                              galleryItemIndex
                            ]);
                          }
                        }

                        return (
                          <figure
                            key={key}
                            className="product-page__gallery__item w-full relative block"
                          >
                            {visible && (
                              <Img
                                className="w-full relative block"
                                fluid={galleryItem.image.childImageSharp.fluid}
                                alt={product.handle}
                              />
                            )}
                          </figure>
                        );
                      }
                    )}
                </div>
              )}

              {device && device !== `desktop` && (
                <div className="w-full relative block">
                  {product?.frontmatter?.gallery?.[0] && (
                    <Carousel
                      withBullets
                      items={product.frontmatter.gallery.map(
                        (galleryItem, galleryItemIndex) => {
                          const key = `gallery-item-${galleryItemIndex}`;

                          return (
                            <figure key={key} className="w-full relative block">
                              <Img
                                className="w-full relative block"
                                fluid={galleryItem.image.childImageSharp.fluid}
                                alt={product.handle}
                              />
                            </figure>
                          );
                        }
                      )}
                    />
                  )}
                </div>
              )}
            </article>
          )}

          {product && (
            <div className="product-page__info grid-end-5 sm:grid-end-12 sm:grid-start-1 sticky xs:relative right-0 xs:right-auto px-4 xs:px-0">
              <article className="h-full relative flex flex-col items-stretch">
                <header className="relative pt-4 xs:pt-0">
                  <Link
                    to={`/collections/${product.frontmatter.collectionHandle}`}
                    className="animation-appear-left-slow animation-delay-2 relative flex items-center xs:mt-8 hover-underline"
                  >
                    <Arrow
                      className="w-4 -mt-1 mr-2"
                      color="black"
                      direction="<"
                    />
                    <span className="b2">
                      Back to {product.frontmatter.collection}
                    </span>
                  </Link>

                  <h1 className="animation-appear-left-slow animation-delay-3 w-full mt-5 xs:mt-6 mb-5 f4">
                    {frontmatter.title}
                  </h1>

                  {options &&
                    Object.keys(options).map(optionKey => {
                      const optionsForKey = options[optionKey];

                      return (
                        <ul className="animation-appear-left-slow animation-delay-4 w-full relative mb-5">
                          {optionsForKey.map(option => {
                            let selected;

                            if (addableProduct?.variant?.selectedOptions) {
                              addableProduct.variant.selectedOptions.forEach(
                                selectedOption => {
                                  if (
                                    selectedOption.name !== optionKey ||
                                    selected
                                  ) {
                                    return;
                                  }

                                  if (selectedOption.value === option) {
                                    selected = true;
                                  }
                                }
                              );
                            }

                            return (
                              <li key={`${optionKey}-${option}`}>
                                <button
                                  key={optionKey}
                                  type="button"
                                  className="block"
                                  onClick={() =>
                                    selectVariantForOption(optionKey, option)
                                  }
                                  style={{ lineHeight: 1 }}
                                >
                                  <span
                                    className={`${
                                      selected ? `underline` : ``
                                    } b2`}
                                  >
                                    {option}
                                  </span>
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    })}

                  <div
                    ref={addToCartRef}
                    className="animation-appear-slow animation-delay-4 w-full relative flex mb-8"
                  >
                    <div className="product-page__quantity relative flex items-center justify-center border mr-3">
                      <button
                        type="button"
                        className={`product-page__quantity__button relative block ${
                          quantity <= 1 ? `pointer-events-none opacity-50` : ``
                        }`}
                        onClick={() => {
                          if (quantity < 2) {
                            return;
                          }

                          setQuantity(quantity - 1);
                        }}
                        transparent
                      >
                        <span className="b1">—</span>
                      </button>

                      <div className="relative block pointer-events-none text-center">
                        <span className="b1">{quantity}</span>
                      </div>

                      <button
                        type="button"
                        className="product-page__quantity__button relative block"
                        onClick={() => setQuantity(quantity + 1)}
                        transparent
                      >
                        <span className="b1">+</span>
                      </button>
                    </div>

                    <Button
                      color={addingToCart ? `grey` : `black`}
                      className={`${
                        stocked ? `` : `opacity-50 pointer-events-none`
                      } ${
                        addingToCart ? `pointer-events-none` : ``
                      } transition-background w-3/4 sm:w-full relative flex items-center justify-center`}
                      onClick={() => {
                        setAddingToCart(true);

                        if (stocked) {
                          addToCart(addableProduct, quantity);
                        }
                      }}
                    >
                      {addableProduct?.variant?.price && (
                        <span className="b1">
                          {addingToCart ? (
                            `Added!`
                          ) : (
                            <>
                              {stocked
                                ? `Add To Cart - $${addableProduct.variant.price}`
                                : `Sold Out`}
                            </>
                          )}
                        </span>
                      )}
                    </Button>
                  </div>

                  {/* {product?.frontmatter?.tagline && (
                    <p className="animation-appear-left-slow animation-delay-3 w-full mt-2 b1">
                      {product.frontmatter.tagline}
                    </p>
                  )} */}
                </header>

                <div className="w-full h-full relative overflow-y-scroll pt-6 pb-32 xs:pb-16 border-t-off-white">
                  <p className="animation-appear-slow animation-delay-5 b1 whitespace-pre-wrap">
                    {product.frontmatter.description}
                  </p>

                  {product.frontmatter?.attributes?.[0] && (
                    <ul className="animation-appear-down-slow animation-delay-5 relative mt-12">
                      {product.frontmatter.attributes.map(
                        (attribute, attributeIndex) => {
                          return (
                            <li
                              key={attribute.name}
                              className={`product-page__attribute w-full relative block border-t-black ${
                                attributeIndex ===
                                product.frontmatter.attributes.length - 1
                                  ? `border-b-black`
                                  : ``
                              }`}
                            >
                              <button
                                type="button"
                                className="w-full relative block pt-2 pb-1"
                                onClick={() => {
                                  setExpandedAttribute(
                                    expandedAttribute === attributeIndex
                                      ? null
                                      : attributeIndex
                                  );
                                }}
                              >
                                <div className="w-full h-full relative flex items-center justify-between">
                                  <span className="b2">{attribute.name}</span>
                                  <span className="mr-1 f4">
                                    {attributeIndex === expandedAttribute
                                      ? `-`
                                      : `+`}
                                  </span>
                                </div>
                              </button>

                              {attributeIndex === expandedAttribute && (
                                <p className="animation-appear-down mt-3 mb-8 b2">
                                  {attribute.content}
                                </p>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                </div>
              </article>
            </div>
          )}
        </section>

        <AppearOnScroll>
          <section
            ref={footerRef}
            className="w-full relative block border-t-black"
          >
            <div className="grid">
              <h3 className="grid-end-8 sm:grid-end-12 mt-20 xs:mt-16 mb-48 xs:mb-24 f3">
                Sourced directly from small, certified organic, tea farmers and
                producers in famous tea growing regions of Japan, our green tea
                is the finest in the world.
              </h3>
            </div>
          </section>
        </AppearOnScroll>

        <ProductGrid
          heading="Recommended Products"
          max={3}
          products={products}
        />
      </Layout>

      <Footer />
    </>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default ProductPage;

export const query = graphql`
  query ProductPage($handle: String!, $markdownId: String!) {
    markdownRemark(id: { eq: $markdownId }) {
      frontmatter {
        title
        shopifyHandle
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tagline
            priority
            collection
            collectionHandle
            shopifyHandle
            priority
            gallery {
              image {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            attributes {
              name
              content
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      handle
      title
      description
      images {
        originalSrc
      }
      productType
      vendor
      variants {
        id
        sku
        title
        image {
          originalSrc
        }
        price
        selectedOptions {
          name
          value
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          variants {
            id
            sku
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            alternative_id
            handle
            variants {
              alternative_id
              title
            }
          }
        }
      }
    }
  }
`;
